import React from 'react'
import { List, ListItem, ListItemButton, Grid } from '@mui/material'
import { PrimaryButton, RegularButton, SlideUpDialog } from '@wavetronix/common-components'
import { useEffect, useState } from 'react'
import RevisionLine from './RevisionLine'
import { getProductCategory } from '../Utils/ProductsMap'
import { downloadBlob } from './Menus/OptionsMenu'

export default function MissingRevisionsModal({ open, onClose, missingRevisions, warehouseOnlyToggle, instance, account }) {
  const [transformedRevisions, setTransformedRevisions] = useState()
  const [selectedItem, setSelectedItem] = useState('')
  useEffect(() => {
    if (missingRevisions) {
      if (warehouseOnlyToggle === true) {
        setTransformedRevisions(missingRevisions[warehouseOnlyToggle === true ? 'includeWarehouse' : 'excludeWarehouse'])
      } else {
        setTransformedRevisions(missingRevisions[warehouseOnlyToggle === true ? 'includeWarehouse' : 'excludeWarehouse'])
      }
    }
  }, [warehouseOnlyToggle, missingRevisions, account, instance])

  const exportMissingRevisions = async () => {
    if (missingRevisions) {
      let csvContent = `Item Number,Revision,Category,Description,Average Price,Quantities\n`
      for (let revision of warehouseOnlyToggle ? missingRevisions.includeWarehouse : missingRevisions.excludeWarehouse) {
        let category = getProductCategory(revision.itemNumber)
        csvContent += `${revision.itemNumber},${revision.revision},${category},${revision.description},${
          revision.averagePrice
        },${revision.quantities.join(',')}\n`
      }
      downloadBlob(
        csvContent,
        `${warehouseOnlyToggle ? 'WarehouseOnly' : 'IncomingOrders'}MissingRevisions.csv`,
        'text/csv;charset=utf-8;'
      )
    }
  }

  const closeDialog = () => {
    setSelectedItem('')
    onClose()
  }

  return (
    <SlideUpDialog
      id='missingRevisionsModal'
      maxWidth={'md'}
      open={open}
      onClose={closeDialog}
      title={<h3 style={{ margin: 0 }}>Missing Revisions</h3>}
      actions={
        <>
          <RegularButton id='missingRevisionsCloseButton' onClick={closeDialog}>
            Close
          </RegularButton>
          <PrimaryButton id='missingRevisionsExportButton' onClick={exportMissingRevisions}>
            Export
          </PrimaryButton>
        </>
      }
    >
      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%' }}>
          <List style={{ maxHeight: 500, overflow: 'auto' }}>
            {transformedRevisions
              ? transformedRevisions.map((revision, index) => (
                  <ListItem disablePadding key={index} divider>
                    <ListItemButton
                      id={`${revision.itemNumber}RevisionListItemButton`}
                      onClick={() => {
                        setSelectedItem(revision)
                      }}
                    >
                      <>
                        <Grid container spacing={2} key={index} style={{ cursor: 'pointer', display: 'flex' }}>
                          <Grid item xs={9}>
                            <div>{revision.itemNumber}</div>
                          </Grid>
                          <Grid item xs={3}>
                            <div style={{ float: 'right' }}>{revision.revision}</div>
                          </Grid>
                        </Grid>
                      </>
                    </ListItemButton>
                  </ListItem>
                ))
              : []}
          </List>
        </div>
        <div style={{ width: '50%', margin: 10 }}>
          {selectedItem ? (
            <RevisionLine revision={selectedItem} instance={instance} account={account} />
          ) : (
            <div>Select an item to see known revisions</div>
          )}
        </div>
      </div>
    </SlideUpDialog>
  )
}
