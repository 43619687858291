import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { List, ListItem, ListItemButton, CircularProgress } from '@mui/material'
import { CenteredDiv } from '@wavetronix/common-components'
import BOMApi from '../Api/BOMApi'

export default function RevisionLine({ revision, instance, account }) {
  const [up, setUp] = useState([])
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['knownRevisions'],
    queryFn: async () =>
      await BOMApi.getItemRevisions(instance, account, revision.itemNumber).then(res => {
        return res
      })
  })

  useEffect(() => {
    refetch()
  }, [revision, refetch])

  useEffect(() => {
    if (data) {
      setUp(data)
    }
  }, [data])

  if (isLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }

  return (
    <>
      <div>Known Revisions for {revision.itemNumber}</div>
      {up ? (
        <List>
          {up.length > 0 ? (
            up.map((u, index) => {
              return (
                <ListItem id={`${revision.itemNumber}ListItem`} disablePadding key={index}>
                  <ListItemButton id={`${revision.itemNumber}ListItemButton`}>
                    <div>
                      {u.revision === '' ? 'Item number in bill of materials, but no revision value set' : `${u.revision}`}
                    </div>
                  </ListItemButton>
                </ListItem>
              )
            })
          ) : (
            <div style={{ padding: '6px 18px' }}>There are no bill of materials for this item.</div>
          )}
        </List>
      ) : (
        <div>Loading...</div>
      )}
    </>
  )
}
