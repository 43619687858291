import { useMsal } from '@azure/msal-react'
import { Button, Card, CardContent, Grid, TextField } from '@mui/material'
import { OpenInNewOutlined } from '@mui/icons-material'
import { Autocomplete, TextField as MuiTextfield } from '@mui/material'
import {
  acquireAccessToken,
  CenteredDiv,
  getConfiguration,
  parseJwt,
  WtxColors,
  PersistantFilterDiv,
  SnackbarVariants
} from '@wavetronix/common-components'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { env } from '../../index.js'
import { usDollar } from '../../Utils/CurrencyConversion'
import { getProductCategory } from '../../Utils/ProductsMap'
import HighLevelProjectionGraph from '../HighLevelProjectionGraph'
import MonthlySummaryGraph from '../MonthlySummaryGraph'
import { makeStyles } from 'tss-react/mui'
import OptionsMenu from '../Menus/OptionsMenu'
import ConfigureMenu from '../Menus/ConfigureMenu'
import InventoryApi from '../../Api/InventoryApi'
import InventoryFilterDrawer, { DEFAULT_INVENTORY_FILTER, filterInventory } from '../Drawers/InventoryFilterDrawer'
import PageNavbar from '../PageNavbar'
import MissingRevisionsModal from '../MissingRevisionsModal'
import { useSnackbar } from 'notistack'

const removeMiscFields = obj =>
  Object.keys(obj)
    .filter(k => !k.includes('Misc.') || k.includes('Click Misc.'))
    .reduce((prev, curr) => {
      prev[curr] = obj[curr]
      return prev
    }, {})

const useStyles = makeStyles()({
  disabledInput: {
    '& .MuiInputBase-root.Mui-disabled, & .MuiInputLabel-shrink': {
      color: 'black'
    }
  }
})

const customConfiguration = token => {
  let config = getConfiguration(token)
  return { ...config, timeout: 125000 }
}

const TimeRangeName = ['One Month', 'Quarter', 'Six Month', 'Year', 'Two Year']
const MonthsFullName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const TimeRange = [1, 3, 6, 12, 24]

const styles = {
  menuItem: {
    cursor: 'pointer',
    padding: '6px 16px',
    fontSize: '16px',
    fontFamily: 'Arial',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  divider: {
    color: 'black',
    fontSize: '14px'
  }
}

function ProjectionPage({ tabState, changeTabState }) {
  const { classes } = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [uploadToken, setUploadToken] = useState('')
  const { instance, accounts } = useMsal()
  const [cleanData, setCleanData] = useState()
  const [shownCleanData, setShownCleanData] = useState()
  const [showMisc, setShowMisc] = useState(true)
  const [summaryData, setSummaryData] = useState()
  const [shownSummaryData, setShownSummaryData] = useState()
  const [demandResponses, setDemandResponses] = useState()
  const [dataItems, setDataItems] = useState([])
  const [months, setMonths] = useState(3)
  const [goalRev, setGoalRev] = useState(0)
  const [fillableRev, setFillableRev] = useState(0)
  const [remainingRev, setRemainingRev] = useState(0)
  const [labelVisible, setLabelVisible] = useState(true)
  const [warehouseOnlyToggle, setWarehouseOnlyToggle] = useState(false)
  const [withIncomingData, setWithIncomingData] = useState()
  const [warehouseOnlyData, setWarehouseOnlyData] = useState()
  const [missingRevisions, setMissingRevisions] = useState()
  const [selectedPart, setSelectedPart] = useState(null)
  const [monthlySums, setMonthlySums] = useState()
  const [partShortages, setPartShortages] = useState()
  const [filter, setFilter] = useState(DEFAULT_INVENTORY_FILTER)
  const [missingRevisionOpen, setMissingRevisionsOpen] = useState(false)

  async function getParts() {
    let token = await acquireAccessToken(instance, accounts, env)
    return axios.get(`${env.urls.inventoryProjectorURL}/api/parts`, customConfiguration(token)).then(res => res.data)
  }

  const { data } = useQuery({ queryKey: ['parts'], queryFn: getParts })

  const { data: inventoryDiffState } = useQuery({
    queryKey: ['realInventoryState'],
    queryFn: async () =>
      await InventoryApi.getInventoryAreEqual(instance, accounts)
        .then(res => {
          return res.data
        })
        .catch(e => {
          enqueueSnackbar(`Error getting inventory difference`, SnackbarVariants.ERROR)
        })
  })

  const CreateCleanedGraphData = useCallback(data => {
    setFillableRev(_ => 0)
    setRemainingRev(_ => 0)
    setGoalRev(_ => 0)
    let requestedProducts = data
      .map(res => getProductCategory(res.itemNumber))
      .filter((product, i, self) => self.indexOf(product) === i)

    let months = data[0] ? data[0].requestedQuantities.length - 1 : 0
    let sortedData = createGraphBaseMonthData(months, requestedProducts)
    let summaryData = createGraphBaseData({ id: 0 }, requestedProducts)
    let newMonthlySums = new Array(months).fill(0)
    for (let item = 0; item < data.length; item++) {
      let product = getProductCategory(data[item].itemNumber)
      for (let month = 0; month < months; month++) {
        let fillableQuantities = data[item].fillableQuantities[month]
        let remainingQuantities = data[item].requestedQuantities[month] - fillableQuantities
        let backfillableQuantities = data[item].backfillableQuantities[month]
        let revenueFillable = data[item].averagePrice * fillableQuantities
        let revenueRemaining = data[item].averagePrice * remainingQuantities
        let revenueBackfillable = data[item].averagePrice * backfillableQuantities
        let shortagesMap = data[item].shortages[month]
        newMonthlySums[month] += revenueFillable + revenueBackfillable

        setFillableRev(fillableRev => fillableRev + revenueFillable + revenueBackfillable)
        setRemainingRev(remainingRev => remainingRev + revenueRemaining - revenueBackfillable)
        setGoalRev(goalRev => goalRev + revenueFillable + revenueRemaining)

        sortedData[month][`${product}fillable`] += fillableQuantities
        sortedData[month][`${product}remaining`] += remainingQuantities
        sortedData[month][`${product}backfillable`] += backfillableQuantities
        sortedData[month][`${product}fillableRev`] += revenueFillable
        sortedData[month][`${product}remainingRev`] += revenueRemaining
        sortedData[month][`${product}backfillableRev`] += revenueBackfillable
        sortedData[month][`${product}totalDemand`] += data[item].requestedQuantities[month] + backfillableQuantities

        for (const [k, v] of Object.entries(shortagesMap)) {
          if (v.path) {
            let path = `${v.path.join('&')}&${k}`

            if (!(path in sortedData[month][`${product}shortages`])) {
              sortedData[month][`${product}shortages`][path] = { ...shortagesMap[k] }
            } else {
              sortedData[month][`${product}shortages`][path].shortage += shortagesMap[k].shortage
            }
          }
        }
      }

      let fillableQuantity = data[item].fillableQuantities[months]
      let backfillableQuantity = data[item].backfillableQuantities[months]
      let remainingQuantity = data[item].totalShortage
      let revenueFillable = data[item].averagePrice * fillableQuantity
      let revenueRemaining = data[item].averagePrice * remainingQuantity
      let revenueBackfillable = data[item].averagePrice * backfillableQuantity
      summaryData[`${product}fillable`] += fillableQuantity
      summaryData[`${product}remaining`] += remainingQuantity
      summaryData[`${product}fillableRev`] += revenueFillable
      summaryData[`${product}remainingRev`] += revenueRemaining
      summaryData[`${product}backfillable`] += backfillableQuantity
      summaryData[`${product}backfillableRev`] += revenueBackfillable
      summaryData[`${product}totalDemand`] += data[item].requestedQuantities[months]

      let summaryShortagesMap = data[item].shortages[months]
      for (let k in summaryShortagesMap) {
        if (!(k in summaryData[`${product}shortages`])) {
          summaryData[`${product}shortages`][k] = { ...summaryShortagesMap[k] }
        } else {
          summaryData[`${product}shortages`][k].shortage += summaryShortagesMap[k].shortage
        }
      }
    }
    let currentMonth = new Date().getUTCMonth()
    setMonthlySums(newMonthlySums.map((sum, i) => ({ revenue: sum, month: MonthsFullName[(currentMonth + i) % 12] })))
    setCleanData(sortedData)
    setSummaryData([summaryData])
  }, [])

  useEffect(() => {
    let itemShortageMap = {}
    if (shownCleanData && dataItems && data) {
      for (const key of data) {
        if (!(key.id in itemShortageMap)) {
          let csv = `Part Shortages for Item Number ${key.id}^`
          csv += ','
          for (const month of shownCleanData) {
            csv += `${month.month}${month.id < data.length - 1 ? ',' : ''}`
          }
          csv += '^'

          for (const product of dataItems) {
            csv += `${product},`

            for (const month of shownCleanData) {
              let shortages = shownCleanData[month.id][`${product}shortages`]
              if (shortages) {
                let mappedShortages = {}
                for (const s of Object.keys(shortages)) {
                  if (shortages[s].itemNumber in mappedShortages) {
                    mappedShortages[shortages[s].itemNumber] += shortages[s].shortage
                  } else {
                    mappedShortages[shortages[s].itemNumber] = shortages[s].shortage
                  }
                }

                if (shortages && key.id in mappedShortages) {
                  csv += `${mappedShortages[key.id]},`
                } else {
                  csv += '0,'
                }
              }
            }
            csv += '^'
          }
          itemShortageMap[key.id] = csv
        }
      }
    }
    setPartShortages(itemShortageMap)
  }, [dataItems, shownCleanData, data])

  useEffect(() => {
    if (cleanData) {
      if (showMisc) {
        setShownCleanData(cleanData)
      } else {
        setShownCleanData(cleanData.map(removeMiscFields))
      }
    }
  }, [cleanData, showMisc])

  useEffect(() => {
    if (summaryData) {
      if (showMisc) {
        setShownSummaryData(summaryData)
      } else {
        setShownSummaryData([removeMiscFields(summaryData[0])])
      }
    }
  }, [summaryData, showMisc])

  useEffect(() => {
    if (demandResponses) {
      let requestedProducts = demandResponses
        .map(res => getProductCategory(res.itemNumber))
        .filter((product, i, self) => self.indexOf(product) === i)

      setDataItems(requestedProducts)
      CreateCleanedGraphData(filterInventory(filter, demandResponses))
    }
  }, [demandResponses, CreateCleanedGraphData, filter])

  useEffect(() => {
    if (warehouseOnlyToggle) {
      setDemandResponses(warehouseOnlyData)
    } else {
      setDemandResponses(withIncomingData)
    }
  }, [warehouseOnlyToggle, warehouseOnlyData, withIncomingData])

  useEffect(() => {
    async function resetUploadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setUploadToken(token)
    }
    if (!uploadToken || parseJwt(uploadToken).exp < Date.now() / 1000) {
      console.log('resetting token')
      resetUploadToken()
    }
  }, [instance, accounts, uploadToken])

  return (
    <PersistantFilterDiv
      resetFilter={() => setFilter(DEFAULT_INVENTORY_FILTER)}
      drawer={<InventoryFilterDrawer filter={filter} setFilter={setFilter} products={dataItems} />}
      page={
        <div style={{ width: '100%', display: 'flex' }}>
          <PageNavbar value={0} onChangeCallback={id => changeTabState(s => ({ ...s, current: id }))} />
          <div style={{ width: '100%' }}>
            <MissingRevisionsModal
              open={missingRevisionOpen}
              onClose={() => setMissingRevisionsOpen(false)}
              missingRevisions={missingRevisions}
              warehouseOnlyToggle={warehouseOnlyToggle}
              instance={instance}
              account={accounts}
            />
            <div>
              <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                <div style={{ justifyContent: 'left', flex: 1, display: 'flex', margin: '20px 20px 0px 24px' }}>
                  <OptionsMenu
                    setWarehouseOnlyData={setWarehouseOnlyData}
                    setWithIncomingData={setWithIncomingData}
                    missingRevisions={missingRevisions}
                    setMissingRevisions={setMissingRevisions}
                    demandResponses={demandResponses}
                    setDemandResponses={setDemandResponses}
                    warehouseOnlyToggle={warehouseOnlyToggle}
                    cleanData={cleanData}
                    months={months}
                    setMonths={setMonths}
                    classes={styles}
                    filter={filter}
                    openMissingRevisionModal={() => setMissingRevisionsOpen(true)}
                  />
                  <ConfigureMenu
                    setLabelVisible={() => setLabelVisible(x => !x)}
                    labelVisible={labelVisible}
                    setShowMisc={() => setShowMisc(x => !x)}
                    showMisc={showMisc}
                    setWarehouseOnlyToggle={() => setWarehouseOnlyToggle(x => !x)}
                    warehouseOnlyToggle={warehouseOnlyToggle}
                    classes={styles}
                  />
                </div>
                <div style={{ justifyContent: 'center', flex: 3, padding: 15 }}>
                  <CenteredDiv>
                    {inventoryDiffState === false ? (
                      <div
                        style={{
                          fontSize: '14px',
                          fontFamily: 'Klavika',
                          color: WtxColors.INNOVATION_RED
                        }}
                      >
                        <CenteredDiv>
                          <h4>Projections based on Fake Inventory</h4>
                        </CenteredDiv>
                        This could be because of edits to inventory or that the file needs to be refreshed. Go to inventory
                        management tab to reset.
                      </div>
                    ) : null}
                  </CenteredDiv>
                </div>
                <div style={{ justifyContent: 'right', flex: 1 }} />
              </div>
            </div>

            <div style={{ margin: '24px 5px 24px 24px' }}>
              <Grid
                style={{
                  height: '95%',
                  width: '100%'
                }}
                container
                spacing={2}
              >
                <Grid
                  style={{
                    width: '100%',
                    height: '100%'
                  }}
                  item
                >
                  <Card variant='outlined'>
                    <CardContent>
                      <CenteredDiv>
                        <h3>
                          Projection for{' '}
                          {demandResponses
                            ? TimeRangeName[TimeRange.indexOf(demandResponses[0].requestedQuantities.length - 1)] === undefined
                              ? `${demandResponses[0].requestedQuantities.length - 1} Months`
                              : TimeRangeName[TimeRange.indexOf(demandResponses[0].requestedQuantities.length - 1)]
                            : 'Inventory'}
                        </h3>
                      </CenteredDiv>
                      <HighLevelProjectionGraph
                        data={shownCleanData}
                        products={dataItems}
                        labels={labelVisible}
                        showAllProducts={false}
                        partShortages={partShortages}
                      />
                    </CardContent>
                  </Card>
                  <div style={{ margin: '40px 0px 24px 0px' }}>
                    <TextField
                      id='goalRevenueTextField'
                      label='Goal Revenue'
                      value={usDollar.format(goalRev)}
                      variant='outlined'
                      className={classes.disabledInput}
                      size='small'
                      disabled={true}
                    />
                    <TextField
                      id='fillableRevenueTextField'
                      label='Fillable Revenue'
                      value={usDollar.format(fillableRev)}
                      variant='outlined'
                      className={classes.disabledInput}
                      size='small'
                      disabled={true}
                    />
                    <TextField
                      id='remainingRevenueTextField'
                      label='Remaining Revenue'
                      value={usDollar.format(remainingRev)}
                      variant='outlined'
                      className={classes.disabledInput}
                      size='small'
                      disabled={true}
                    />
                    <div style={{ float: 'right', display: 'flex' }}>
                      <Autocomplete
                        id='selectedPartTextField'
                        style={{ width: '200px' }}
                        options={data ? data.map(part => part.id) : []}
                        renderInput={params => <MuiTextfield {...params} label='Search Parts' size='small' variant='filled' />}
                        value={selectedPart}
                        onChange={(_, newValue) => setSelectedPart(newValue)}
                      />
                      <Button
                        id='openSelectedPartButton'
                        variant='contained'
                        style={{ minWidth: 40, maxWidth: 40, minHeight: 47, maxHeight: 47, backgroundColor: WtxColors.GOLD }}
                        onClick={() =>
                          window.open(
                            `../part?itemNumber=${selectedPart}&shortages=${partShortages ? partShortages[selectedPart] : ''}`,
                            '_blank'
                          )
                        }
                        title='Open part info page in new tab'
                        disabled={!selectedPart}
                      >
                        <OpenInNewOutlined />
                      </Button>
                    </div>
                  </div>
                  <br />
                  <br />
                  <Card variant='outlined'>
                    <CardContent>
                      <CenteredDiv>
                        <h3>
                          {demandResponses ? TimeRangeName[TimeRange.indexOf(demandResponses[0].requestedQuantities.length)] : ''}{' '}
                          Summary
                        </h3>
                      </CenteredDiv>
                      <HighLevelProjectionGraph
                        data={shownSummaryData}
                        products={dataItems}
                        variant='summary'
                        labels={labelVisible}
                        showAllProducts={true}
                        partShortages={partShortages}
                      />
                    </CardContent>
                  </Card>
                  <br /> <br />
                  <Card variant='outlined' sx={{ marginBottom: '100px' }}>
                    <CardContent>
                      <CenteredDiv>
                        <h3>Projected Monthly Revenue</h3>
                      </CenteredDiv>
                      <MonthlySummaryGraph data={monthlySums} />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      }
    />
  )
}

function createGraphBaseMonthData(months, requestedProducts) {
  let data = []
  for (let month = 0; month < months; month++) {
    let monthlyData = {
      id: month,
      month: MonthsFullName[(new Date().getUTCMonth() + month) % 12]
    }
    data.push(createGraphBaseData(monthlyData, requestedProducts))
  }
  return data
}

function createGraphBaseData(data, requestedProducts) {
  for (let product of requestedProducts) {
    data[product] = product
    data[`${product}fillable`] = 0
    data[`${product}backfillable`] = 0
    data[`${product}remaining`] = 0
    data[`${product}fillableRev`] = 0
    data[`${product}backfillableRev`] = 0
    data[`${product}remainingRev`] = 0
    data[`${product}totalDemand`] = 0
    data[`${product}shortages`] = {}
  }
  return data
}

export default ProjectionPage
