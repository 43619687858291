import { DialogContent, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@mui/material'
import { SlideUpDialog } from '@wavetronix/common-components'
import { OpenInNewOutlined } from '@mui/icons-material'
import SubstitutesList from './SubstitutesList'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'

const classes = {
  paper: {
    position: 'absolute',
    width: '600px',
    height: '600px',
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    padding: '16px 32px 24px'
  },
  itemDescription: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}

export default function ShortagesModal({ shortages, open, onClose, data, partShortages }) {
  return (
    <SlideUpDialog
      id='shortagesModal'
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
      title={<h3 style={{ margin: 0 }}>Part Shortages</h3>}
    >
      <DialogContent sx={{ maxHeight: '600px' }}>
        <div style={{ fontSize: '12px' }}>
          <em>Part shortages are only generated for items that have a stored BOM</em>
        </div>
        <TableContainer>
          <Table style={{ maxWidth: '600px' }}>
            <TableBody>
              <TableRow style={{ height: '30px' }}>
                <TableCell>Part Number</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Substitutes</TableCell>
                <TableCell>Shortage</TableCell>
              </TableRow>
              {shortages
                ? Object.keys(shortages).map(key => {
                    let itemNumber = shortages[key].itemNumber
                    return (
                      <TableRow key={`${key}-shortages`}>
                        <Tooltip
                          title={
                            <div style={{ display: 'flex' }}>
                              {shortages[key].path
                                ? shortages[key].path.map((pathString, index) => {
                                    if (index < shortages[key].path.length - 1) {
                                      return (
                                        <div key={pathString}>
                                          {pathString}
                                          <ArrowRightAltIcon style={{ marginBottom: '-8px' }} />
                                        </div>
                                      )
                                    } else {
                                      return (
                                        <div key={pathString} style={{ marginTop: '6px' }}>
                                          {pathString}
                                        </div>
                                      )
                                    }
                                  })
                                : 'Top Level Product'}
                            </div>
                          }
                        >
                          <TableCell>
                            <a
                              href={`../part?itemNumber=${itemNumber}&shortages=${
                                partShortages ? partShortages[itemNumber] : ''
                              }`}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              {itemNumber.toUpperCase()}
                              <OpenInNewOutlined style={{ fontSize: '13px' }} />
                            </a>
                          </TableCell>
                        </Tooltip>
                        <Tooltip title={shortages[key].description}>
                          <TableCell sx={classes.itemDescription} style={{ maxWidth: '150px' }}>
                            {shortages[key].description}
                          </TableCell>
                        </Tooltip>
                        <TableCell sx={classes.itemDescription} style={{ maxWidth: '100px' }}>
                          <SubstitutesList substitutes={shortages[key].substitutes} newTab partShortages={partShortages} />
                        </TableCell>
                        <TableCell>{shortages[key].shortage}</TableCell>
                      </TableRow>
                    )
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </SlideUpDialog>
  )
}
