import React, { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { CircularProgress, InputAdornment } from '@mui/material'
import {
  CenteredDiv,
  SnackbarVariants,
  CustomDataGrid,
  PrimaryButton,
  RegularButton,
  WtxColors
} from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import { TextField } from '@mui/material'
import PageNavbar from '../PageNavbar'
import InventoryApi from '../../Api/InventoryApi'
import SearchIcon from '@mui/icons-material/Search'
import { containsSubstring } from '../../Utils/StringExtensions'

const searchInventory = (searchValue, inventory) => {
  return inventory ? inventory.filter(inv => containsSubstring(inv.itemName, searchValue) || searchValue === '') : []
}

export default function InventoryManagement({ tabState, changeTabState }) {
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [inventory, setInventory] = useState()
  const [searchItem, setSearchItem] = useState('')
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['inventory'],
    queryFn: async () =>
      await InventoryApi.getInventory(instance, accounts)
        .then(res => res.data)
        .catch(e => {
          enqueueSnackbar(`${e.response.data}`, SnackbarVariants.ERROR)
        })
  })
  const { data: inventoryDiffState, refetch: inventoryDiffStateRefetch } = useQuery({
    queryKey: ['realInventoryState'],
    queryFn: async () =>
      await InventoryApi.getInventoryAreEqual(instance, accounts)
        .then(res => {
          return res.data
        })
        .catch(e => {
          enqueueSnackbar(`Error getting inventory difference`, SnackbarVariants.ERROR)
        })
  })

  const columns = [
    {
      headerName: 'Item Number',
      field: 'itemName',
      flex: 1
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 2,
      valueGetter: item => (item.row.description ? item.row.description.replaceAll(',', ' ') : '')
    },
    {
      headerName: 'Quantity',
      field: 'onHandQuantity',
      flex: 1,
      renderCell: item => {
        return (
          <TextField
            id={`${item.row.itemName}QuantityTextField`}
            inputProps={{ min: 0 }}
            type='number'
            variant='outlined'
            style={{ width: '100%' }}
            onChange={e => {
              let newInventory = { ...inventory }
              newInventory[item.row.itemName].onHandQuantity = e.target.value
              setInventory(newInventory)
            }}
            label={'Quantity On Hand'}
            value={item.row.onHandQuantity}
            size='small'
          />
        )
      }
    }
  ]

  const InventoryToCSV = async () => {
    let csv = 'Item Name,Item Description,Item Class,Subinventory,List Price,On-Hand Quantity\n'
    for (let key in inventory) {
      csv += `${inventory[key].itemName},${inventory[key].description.replaceAll(',', ' ')},${inventory[key].itemClass},${
        inventory[key].subInventory
      },${inventory[key].listPrice},${inventory[key].onHandQuantity}\n`
    }
    return new Blob([csv], {
      type: 'data:text/csv;charset=utf-8'
    })
  }

  const SaveInventory = async () => {
    const formData = new FormData()
    formData.append('file', await InventoryToCSV())

    let key = enqueueSnackbar('Uploading inventory...', SnackbarVariants.SUCCESS)
    await InventoryApi.uploadInventory(instance, accounts, formData)
      .then(async res => {
        closeSnackbar(key)
        await inventoryDiffStateRefetch()
        enqueueSnackbar('Uploaded inventory succes', SnackbarVariants.SUCCESS)
      })
      .catch(e => {
        closeSnackbar(key)
        enqueueSnackbar(e.response.data, SnackbarVariants.ERROR)
      })
  }

  const ResetInventory = async () => {
    let key = enqueueSnackbar('Reseting inventory...', SnackbarVariants.LOADING)
    await InventoryApi.resetInventory(instance, accounts)
      .then(async res => {
        closeSnackbar(key)
        await inventoryDiffStateRefetch()
        await refetch()
        enqueueSnackbar('Reset inventory succes', SnackbarVariants.SUCCESS)
      })
      .catch(e => {
        closeSnackbar(key)
        enqueueSnackbar(e.response.data, SnackbarVariants.ERROR)
      })
  }

  useEffect(() => {
    if (data) {
      setInventory(data)
    }
  }, [data])

  if (isLoading) {
    return (
      <div style={{ display: 'flex' }}>
        <PageNavbar value={1} onChangeCallback={id => changeTabState(s => ({ ...s, current: id }))} />
        <div style={{ width: '100%' }}>
          <CenteredDiv>
            <CircularProgress />
          </CenteredDiv>
        </div>
      </div>
    )
  }

  return (
    <div style={{ display: 'flex' }}>
      <PageNavbar value={1} onChangeCallback={id => changeTabState(s => ({ ...s, current: id }))} />
      <div style={{ width: '100%', padding: 15 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <PrimaryButton id='saveInventoryButton' style={{ marginRight: '10px' }} onClick={SaveInventory}>
              Save
            </PrimaryButton>
            <RegularButton id='resetInventoryButton' onClick={ResetInventory}>
              Reset
            </RegularButton>
          </div>
          {inventoryDiffState === false ? (
            <div
              style={{
                fontSize: '14px',
                fontFamily: 'Klavika',
                color: WtxColors.INNOVATION_RED
              }}
            >
              <CenteredDiv>
                <h4>Projections based on Fake Inventory</h4>
              </CenteredDiv>
              This could be because of edits to inventory or that the file needs to be refreshed. Press the Reset button to use
              the latest inventory.
            </div>
          ) : null}
          <div>
            <TextField
              id='searchItemNumberTextField'
              label={'Item Number'}
              size='small'
              value={searchItem}
              variant='outlined'
              onChange={e => setSearchItem(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </div>
        </div>

        <div style={{ width: '100%', marginBottom: '100px', marginTop: 15 }}>
          <CustomDataGrid
            rows={
              inventory
                ? searchInventory(
                    searchItem,
                    Object.keys(inventory).map((k, num) => ({ id: num, ...data[k] }))
                  )
                : []
            }
            loading={isLoading}
            columns={columns}
            rowHeight={60}
          />
        </div>
      </div>
    </div>
  )
}
